<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>JD&C Services Contractor Portal</v-card-title>
      <v-card-text
        >Digitally review and approve your tickets and automatically submit your invoice on the go.</v-card-text
      >
    </v-card>
    <!-- <v-card class="mb-6"> -->

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <div class="circle-chart-container">
            <CircleChart
              :series="uninvoicedTicketsSeries"
              :labels="uninvoicedTicketsLabels"
              title="Uninvoiced Tickets"
              :isLoading="uninvoicedIsLoading"
            />
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <div class="circle-chart-container">
            <CircleChart
              :series="currentMonthTicketsSeries"
              :labels="currentMonthTicketsLabels"
              :title="`${currentMonth} Tickets`"
              :isLoading="currentMonthIsLoading"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- </v-card>  -->
    <v-card class="mb-6 mt-6">
      <v-card-title>Create Invoice Page</v-card-title>
      <v-card-text
        >The Create Invoice Page allows contractors to generate and automatically submit an invoice to JD&C
        Services.</v-card-text
      >
      <v-card-text
        >The Create Invoice Page requires the following information to be inputted in order to submit an invoice:
        <ul>
          <li><strong>Invoice Month:</strong> The date of the tickets that are being invoiced.</li>
          <li><strong>Unit:</strong> The unit that the invoice is being submitted for.</li>
          <li><strong>Invoice Date:</strong> The date that will appear on the invoice submitted to JD&C.</li>
          <li>
            <strong>A Unique Invoice Number:</strong> The invoice number that will display on the invoice submitted.
          </li>
          <li>
            <strong>Approved Tickets:</strong> An invoice cannot be submitted until all tickets have been approved by
            the contractor
          </li>
        </ul>
      </v-card-text>
      <v-card-text
        >Once the invoice month and the unit number have been selected, the invoice totals will load and appear on the
        screen for review. These totals are the calculated amounts that will be on your invoice.</v-card-text
      >
      <v-card-text
        >Beneath the invoice totals are two inputs of note:
        <ul>
          <li>
            <strong>Show Report Data:</strong> If enabled, this toggle will show the Contractor Balance Sheet Report.
            This report is identical to the Report page and includes a list of all tickets related to the invoice month
            and the unit selected. Tickets can also be approved from this total.
          </li>
          <li>
            <strong>I Agree To the Totals:</strong> Once all tickets as well as the totals have been approved, the "I
            agree to the totals" checkbox must be clicked to unlock the View PDF and Submit buttons
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        There are two buttons that are displayed on the Create Invoice page:
        <ul>
          <li>
            <strong>View PDF:</strong> The View PDF button will generate and download a PDF copy of the invoice for your
            records. This is the exact copy of the invoice that will be generated and submitted to JD&C.
          </li>
          <li>
            <strong>Submit:</strong> The Submit button will generate the invoice and submit the invoice to JD&C. This is
            the last step of the process and once the confirmation appears on the screen that the invoice has been
            submitted, no other action needs to be taken.
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        Limitations on the Create Invoice page:
        <ul>
          <li><strong>Lockout Period:</strong> The Create Invoice Page is locked on the 1st and 2nd of every month.</li>
          <li>
            <strong>Tickets:</strong> If a job is under review at JD&C, tickets will be locked from being approved or
            unapproved. The approve checkbox will be disabled and a tooltip will appear when a job is under review. Once
            an invoice is submitted, tickets are permanently locked from being unapproved.
          </li>
        </ul>
      </v-card-text>
      <v-card-text class="font-italic">
        * Tickets that have been created within the last 6 hours will not appear on the Create Invoice page.
      </v-card-text>
    </v-card>

    <v-card class="my-6">
      <v-card-title>Report Page</v-card-title>
      <v-card-text
        >The Report Page contains a searchable form to display all tickets that are related to your company's units. You
        can filter the report down by date, unit, customer, job type (production, service, etc...), and the operations
        (Hinton or Rocky Mountain House)</v-card-text
      >
      <v-card-text
        >Generating the report will display all tickets within a table. Tickets can be approved by clicking the checkbox
        in the left most column. All tickets must be approved before an invoice can be submitted.</v-card-text
      >
      <v-card-text
        >If an error or discrepancy has been found between your copy of a ticket and the copy that is within the report,
        please phone the office at (780) 865-1090 and speak to Ashley to resolve the issue. A formal dispute resolution
        process will be defined in the coming months.</v-card-text
      >      
      <v-card-text class="font-italic">
        * Tickets that have been created within the last 6 hours will not appear on the Report page.
      </v-card-text>

    </v-card>

    <v-card>
      <v-card-title>Submitted Invoices Page</v-card-title>
      <v-card-text
        >The Submitted Invoices Page allows you to select a month and one, or all, of your company's units to generate a
        report showing details such as whether each unit's invoice has been submitted and emailed to
        JD&amp;C.</v-card-text
      >
      <v-card-text
        >If a unit has one or more submitted invoices for the selected month, they will show in the table and allow you
        to download the submitted invoice PDF. Also, if a unit does not have an invoice submitted it will still show in
        the table but it is only there to show you that it has not been invoiced for that month yet.</v-card-text
      >
      <v-card-text
        >When an invoice is submitted through the portal it should automatically email the invoice to JD&amp;C. If the
        email process fails when submitting an invoice you'll be able to see this in the report. If the badge in the
        "Emailed" column is red it means that the invoice was submitted but the email wasn't sent. You can then download
        the invoice PDF and manually email it to admin@jdcservices.ca and then click the red badge to update the invoice
        emailed status to sent.
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import moment from 'moment'
import CircleChart from './components/CircleChart.vue'
import { generateTicketList } from './components/GenerateTicketListV2.js'

export default {
  async mounted() {
    this.uninvoicedIsLoading = true
    this.currentMonthIsLoading = true
    await this.getUnits()
    this.fetchTickets()
  },
  components: {
    CircleChart,
  },
  data() {
    return {
      uninvoicedTickets: [],
      uninvoicedTicketsSeries: [],
      uninvoicedTicketsLabels: [],
      uninvoicedIsLoading: false,

      currentMonthTickets: [],
      currentMonthTicketsSeries: [],
      currentMonthTicketsLabels: [],
      currentMonthIsLoading: false,

      units: [],
    }
  },
  computed: {
    currentMonth() {
      return moment().format('MMMM')
    },
  },
  methods: {
    async getUnits() {
      try {
        const doc = await firebase.firestore().collection('orgs').doc(`${sessionStorage.selectedOrg}`).get()
        if (doc.exists) {
          this.units = doc.data().units
        }
      } catch (error) {
        console.error(error)
      }
    },

    async fetchTickets() {
      try {
        const [uninvoicedResponse, currentMonthResponse] = await Promise.all([
          this.getTickets(moment('2024-03-01'), moment()),
          this.getTickets(moment().startOf('month'), moment()),
        ])
        this.processUninvoicedTickets(uninvoicedResponse.data)
        this.processCurrentMonthTickets(currentMonthResponse.data)
      } catch (error) {
        console.error('Error fetching tickets:', error)
        this.$toasted.show('Error fetching tickets', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
        this.uninvoicedIsLoading = false
        this.currentMonthIsLoading = false
      }
    },

    async getTickets(dateA, dateB) {
      const response = await axios.post(
        'https://wilardapi.azurewebsites.net/api/Tickets/contractor-balance-sheet',
        // 'https://localhost:44370/api/Tickets/contractor-balance-sheet',
        {
          unitID: this.units.toString(),
          production: 'Yes,No,N12,D15,T0,L12',
          commision: '0.15',
          dateA: dateA.format('YYYY-MM-DD'),
          dateB: dateB.format('YYYY-MM-DD'),
          customer: 0,
          operations: '',
        },
        {
          headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
          // headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY_DEV },
        },
      )
      return response
    },

    async processUninvoicedTickets(ticketList) {
      this.uninvoicedTickets = await generateTicketList(ticketList)
      this.uninvoicedTickets = this.uninvoicedTickets.filter(ticket => !ticket.contractorInvoiced)

      const { unitIDs, counts } = this.getUnitIDsAndCounts(this.uninvoicedTickets)
      this.uninvoicedTicketsSeries = counts
      this.uninvoicedTicketsLabels = unitIDs

      this.uninvoicedIsLoading = false
    },

    async processCurrentMonthTickets(ticketList) {
      this.currentMonthTickets = await generateTicketList(ticketList)

      const { unitIDs, counts } = this.getUnitIDsAndCounts(this.currentMonthTickets)
      this.currentMonthTicketsSeries = counts
      this.currentMonthTicketsLabels = unitIDs

      this.currentMonthIsLoading = false
    },

    getUnitIDsAndCounts(tickets) {
      const unitCounts = tickets.reduce((acc, ticket) => {
        if (!acc[ticket.unitID]) {
          acc[ticket.unitID] = new Set()
        }
        acc[ticket.unitID].add(ticket.ticketNum)
        return acc
      }, {})

      const unitIDs = Object.keys(unitCounts)
      const counts = unitIDs.map(unitID => unitCounts[unitID].size)

      return { unitIDs, counts }
    },
  },
}
</script>

<style scoped>
.circle-chart-container {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}
</style>
